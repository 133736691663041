

















































import { Component, Vue } from "vue-property-decorator";
import PrimaryButton from "@/components/general/PrimaryButton.vue";
import InputText from "@/components/general/InputText.vue";
import InputDropdown from "@/components/general/InputDropdown.vue";
import Modal from "@/components/Modal.vue";
import Axios from "axios";

@Component({
	components: { PrimaryButton, InputText, InputDropdown, Modal },
})
export default class RegisterFormCS extends Vue {
	courseTypes = [
		{
			label: "Lecture",
			options: ["without tutorial", "with tutorial"],
		},
		{
			label: "Seminar",
			options: ["without tutorial", "with tutorial"],
		},
		{
			label: "Project seminar",
			options: ["without tutorial", "with tutorial"],
		},
		{
			label: "Lab course",
			options: ["without tutorial", "with tutorial"],
		},
		{
			label: "Media project",
			options: ["without tutorial", "with tutorial"],
		},
	];
	course = {
		mail: "",
		honor: "",
		first: "",
		last: "",
		title: "",
		lsf: "",
		type: "---",
		notes: "",
	};
	modalSettings = {
		header: "",
		content: "",
		open: false,
	};
	logIt(): void {
		const uploadCourse = { ...this.course };
		if (uploadCourse.lsf.trim() === "") uploadCourse.lsf = "99";
		Axios.post("assets/php/handleRegistrations.php", JSON.stringify({ call: "addCSRegistration", payload: uploadCourse }))
			.then(response => {
				if (+response.data === 1) this.saveSuccess();
				else this.saveError();
			})
			.catch(() => this.serverError());
	}
	saveSuccess(): void {
		this.modalSettings.header = "Data transmission successful";
		this.modalSettings.content = this.generateModalContent();
		this.modalSettings.open = true;
		this.course.title = "";
		this.course.notes = "";
	}
	saveError(): void {
		this.modalSettings.header = "Error in data transmission";
		this.modalSettings.content = "Unfortunately, an error occurred while transferring your data. If this problem occurs repeatedly, please contact qualis@mx.uni-saarland.de";
		this.modalSettings.open = true;
	}
	serverError(): void {
		this.modalSettings.header = "Error communicating with the server";
		this.modalSettings.content = "Unfortunately, an error has occurred while communicating with the server. If this problem occurs repeatedly, please contact qualis@mx.uni-saarland.de";
		this.modalSettings.open = true;
	}
	generateModalContent(): string {
		return (
			"<p>The data for your evaluation has been successfully saved.</p>" +
			"<h2 class='font-bold'>Summary</h2>" +
			`<ul><li>Title of the course: ${this.course.title}</li>` +
			(this.course.lsf.trim() !== "" ? `<li>LSF Number: ${this.course.lsf}</li>` : "") +
			`<li>Lecturer(s): ${this.course.honor.trim()} ${this.course.first.trim()} ${this.course.last.trim()}</li>` +
			`<li>Type of the course: ${this.course.type}</li></ul>`
		);
	}
}
