

















import RegisterFormCS from "@/components/registration-cs/RegisterFormCS.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
	components: { RegisterFormCS },
})
export default class RegistrationCS extends Vue {}
